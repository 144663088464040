import * as React from "react";
import {
  Box,
  Card,
  Grid,
  CardHeader,
  Paper,
  Typography
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { getResources, useRedirect } from "react-admin";
import { useSelector } from "react-redux";
import DefaultIcon from "@material-ui/icons/ViewList";

const resourceStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(4)
    },
    paper: {
      height: 150,
      width: 200,
      padding: theme.spacing(2),
      margin: theme.spacing(2),
      textAlign: "center"
    },
    card: {
      minWidth: "300px"
    },
    title: {
      marginBottom: theme.spacing(4),
      fontWeight: "bold"
    }
  })
);

const ResourceItem = ({ item }: any) => {
  const classes = resourceStyles();
  const redirect = useRedirect();

  const handleClick = () => redirect(`/${item.name}`);
  return (
    <Paper className={classes.paper} onClick={handleClick}>
      <Typography
        component="h2"
        className={classes.title}
        color="textSecondary"
        gutterBottom
      >
        {(item.options && item.options.label) || item.name}
      </Typography>
      {item.icon ? (
        <item.icon style={{ width: "80px", height: "80px" }} />
      ) : (
        <DefaultIcon />
      )}
    </Paper>
  );
};

const Dashboard: React.FC = () => {
  const classes = resourceStyles();
  const resources = useSelector(getResources);

  return (
    <div style={{ padding: "8px 16px" }}>
      <Box display="block">
        <Card style={{ textAlign: "center" }}>
          <CardHeader title="Dashboard" />
        </Card>
      </Box>
      <Grid
        container
        className={classes.root}
        direction="column"
        justify="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item xs={12}>
          <Grid container justify="center" spacing={6}>
            {resources.map((resource) => (
              <ResourceItem item={resource} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
