import * as React from "react";

import {
  Button,
  FilterList,
  FilterListItem,
  LinearProgress,
  useQueryWithStore
} from "react-admin";

import MemoryIcon from "@material-ui/icons/Memory";

import uniq from "lodash/uniq";

import { SideCardFilter } from "../common";
import { Motivation, MotivationType } from "./types";

const VariableNameFilter: React.FC = (props) => {
  const [variableNames, setVariableNames] = React.useState<string[]>([]);

  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "motivations",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "variable_name", order: "ASC" }
    }
  });

  React.useEffect(() => {
    if (data) {
      const arr = uniq(
        data.map((el: Motivation) => el.variable_name)
      ) as string[];
      setVariableNames(arr);
    }
  }, [data]);

  if (loading) {
    return <LinearProgress />;
  }
  return (
    <FilterList label="Variable Name" icon={<MemoryIcon />} {...props}>
      {variableNames.map((name, i) => (
        <FilterListItem label={name} value={{ variable_name: name }} key={i} />
      ))}
    </FilterList>
  );
};

const MotivationTypeFilter: React.FC = (props) => {
  const [motivationTypes, setMotivationTypes] = React.useState<string[]>([]);

  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "motivation-types",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "ASC" }
    }
  });

  React.useEffect(() => {
    if (data) {
      const arr = uniq(data.map((el: MotivationType) => el.name)) as string[];
      setMotivationTypes(arr);
    }
  }, [data]);

  if (loading) {
    return <LinearProgress />;
  }
  return (
    <FilterList label="Motivation Type" icon={<MemoryIcon />} {...props}>
      {motivationTypes.map((name, i) => (
        <FilterListItem
          label={name}
          value={{ motivation_type: name }}
          key={i}
        />
      ))}
    </FilterList>
  );
};

export const FilterSidebar: React.FC<any> = ({ setFilters }) => {
  return (
    <SideCardFilter>
      <VariableNameFilter />
      <MotivationTypeFilter />
      <Button
        style={{ marginTop: "2em" }}
        onClick={() => setFilters({})}
        label="Clear filters"
      />
    </SideCardFilter>
  );
};
