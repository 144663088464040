import React from "react";
import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  FieldProps,
  FunctionField,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from "react-admin";
import {
  ShowActionsToolbar,
  CreateActionsToolbar,
  EditActionsToolbar,
  BGBooleanField
} from "../common";
import { validateDescription, validatePromoCode } from "../validators";
import { FilterSidebar } from "./filters";

export interface Promocode {
  id: string;
  code: string;
  type: string;
  description?: string;
  enabled: boolean;
  limit: number;
  times_used: number;
  created: Date;
  expires?: Date;
  packages: number;
  access_level_valid_for: Date;
}

interface PromocodeProps extends FieldProps {
  source?: keyof Promocode;
  record?: Promocode;
}

const PromocodeTitle: React.FC<PromocodeProps> = ({ record = {} }) => (
  <span>Promocode {record ? `"${record.code}"` : ""}</span>
);

const PromocodeList: React.FC = (props) => (
  <List
    aside={<FilterSidebar />}
    bulkActionButtons={false}
    {...props}
    perPage={25}
  >
    <Datagrid rowClick="show">
      <TextField source="code" />
      <FunctionField
        label="Description"
        render={(record: any) =>
          `${
            record && record.description.length > 50
              ? record.description.slice(0, 50) + "..."
              : record.description
          }`
        }
      />
      <ReferenceField source="package" reference="packages">
        <TextField source="name" />
      </ReferenceField>
      <BGBooleanField source="enabled" />
      <NumberField source="limit" />
      <NumberField source="times_used" label="Used" />
      <DateField source="expires" showTime />
      <DateField source="created" showTime />
      <EditButton />
    </Datagrid>
  </List>
);

const PromocodeShow: React.FC = (props) => (
  <Show actions={<ShowActionsToolbar />} title={<PromocodeTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="Id" source="id" />
      <TextField source="code" />
      <TextField source="description" />
      <ReferenceField source="package" reference="packages">
        <TextField source="name" />
      </ReferenceField>
      <BGBooleanField source="enabled" />
      <NumberField source="limit" />
      <NumberField source="times_used" />
      <DateField source="expires" showTime />
      <DateField source="created" showTime />
    </SimpleShowLayout>
  </Show>
);

const PromocodeCreate: React.FC = (props) => (
  <Create actions={<CreateActionsToolbar />} {...props}>
    <SimpleForm redirect="list">
      <TextInput
        source="code"
        validate={validatePromoCode}
        parse={(v: string) =>
          v
            .replace(/([^a-zA-Z0-9_-])+/g, "")
            .replace(/[-]+/g, "-")
            .replace(/[_]+/g, "_")
        }
      />
      <TextInput
        fullWidth
        source="description"
        validate={validateDescription}
      />
      <NumberInput source="limit" defaultValue={0} />
      <ReferenceInput source="package" reference="packages">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <DateTimeInput source="expires" />
      <BooleanInput source="enabled" defaultValue={true} />
    </SimpleForm>
  </Create>
);

const PromocodeEdit: React.FC = (props) => (
  <Edit
    actions={<EditActionsToolbar />}
    title={<PromocodeTitle />}
    mutationMode="pessimistic"
    {...props}
  >
    <SimpleForm redirect="list">
      <TextInput disabled source="id" />
      <TextInput disabled source="code" />
      <TextInput
        fullWidth
        source="description"
        validate={validateDescription}
      />
      <NumberInput source="limit" defaultValue={0} />
      <ReferenceInput source="package" reference="packages" isRequired>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <DateTimeInput source="expires" />
      <BooleanInput source="enabled" defaultValue={true} />
    </SimpleForm>
  </Edit>
);

export default {
  list: PromocodeList,
  create: PromocodeCreate,
  edit: PromocodeEdit,
  show: PromocodeShow
};
