import * as React from "react";
import {
  Button,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  Loading,
  useQuery
} from "react-admin";
import {
  endOfYesterday,
  startOfMonth,
  endOfMonth,
  addMonths,
  addWeeks,
  endOfDay,
  addYears,
  startOfYear,
  endOfYear,
  endOfToday
} from "date-fns";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import { SideCardFilter } from "../common";
import { Package } from "../packages";

const PackagesFilter: React.FC = () => {
  const { data, loading, error } = useQuery({
    type: "getList",
    resource: "packages",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "ASC" },
      filter: {}
    }
  });

  if (loading) return <Loading />;
  if (error) {
    return <p>ERROR</p>;
  }

  if (!data) return null;

  return (
    <FilterList label={`Packages`} icon={<SupervisedUserCircleOutlinedIcon />}>
      {data.map((el: Package) => (
        <FilterListItem label={el.name} value={{ package: el.id }} />
      ))}
    </FilterList>
  );
};

const LimitsFilter: React.FC = () => {
  return (
    <FilterList label="Limits" icon={<SupervisedUserCircleOutlinedIcon />}>
      <FilterListItem label="Unlimited" value={{ limit: 0 }} />
      <FilterListItem
        label="1 - 10"
        value={{
          limit: {
            $lte: 10,
            $gt: 0
          }
        }}
      />
      <FilterListItem
        label="11 - 50"
        value={{
          limit: {
            $lt: 50,
            $gt: 10
          }
        }}
      />
      <FilterListItem
        label="51 - 100"
        value={{
          limit: {
            $lt: 100,
            $gt: 50
          }
        }}
      />
      <FilterListItem
        label="Above 100"
        value={{
          limit: {
            $gt: 100
          }
        }}
      />
    </FilterList>
  );
};

const ExpiresFilter: React.FC = (props) => {
  return (
    <FilterList label="Expires" icon={<AccessTimeIcon />} {...props}>
      <FilterListItem
        label="Today"
        value={{
          expires: {
            $gte: endOfYesterday().toISOString(),
            $lte: endOfToday().toISOString()
          }
        }}
      />
      <FilterListItem
        label="Next week"
        value={{
          expires: {
            $gte: endOfDay(new Date()).toISOString(),
            $lte: endOfDay(addWeeks(new Date(), 1)).toISOString()
          }
        }}
      />
      <FilterListItem
        label="Next month"
        value={{
          expires: {
            $gte: startOfMonth(addMonths(new Date(), 1)).toISOString(),
            $lte: endOfMonth(addMonths(new Date(), 1)).toISOString()
          }
        }}
      />
      <FilterListItem
        label="Next Year"
        value={{
          expires: {
            $gte: startOfYear(addYears(new Date(), 1)).toISOString(),
            $lte: endOfYear(addYears(new Date(), 1)).toISOString()
          }
        }}
      />
    </FilterList>
  );
};

export const FilterSidebar: React.FC<any> = ({ setFilters }) => {
  return (
    <SideCardFilter>
      <FilterLiveSearch source="code" />
      <PackagesFilter />
      <LimitsFilter />
      <ExpiresFilter />
      <Button
        style={{ marginTop: "2em" }}
        onClick={() => setFilters({})}
        label="Clear filters"
      />
    </SideCardFilter>
  );
};
