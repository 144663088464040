import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  UrlField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  required,
  EditButton,
  FieldProps,
  Show,
  SimpleShowLayout,
  NumberField,
  BooleanField,
  BooleanInput,
  ReferenceField,
  SelectInput,
  FileInput,
  FileField,
  NumberInput
} from "react-admin";
import {
  CreateActionsToolbar,
  EditActionsToolbar,
  ShowActionsToolbar
} from "../common";
import { User } from "../users/types";

export interface Firmware {
  id: string;
  filename: string;
  file_url: string;
  file_size: string;
  checksum: string;
  description: string;
  version: number;
  device_version: number;
  enabled: boolean;
  type: string;
  created?: Date;
  created_by?: User;
  modified?: Date;
  modified_by?: User;
}

const available_device_versions = [
  { id: 1, name: "Version 1" },
  { id: 2, name: "Version 2" }
];
interface FirmwareProps extends FieldProps {
  source?: keyof Firmware;
  record?: Firmware;
}

const FirmwareTitle: React.FC<FirmwareProps> = ({ record = {} }) => (
  <span>Firmware {record ? `"${record.description}"` : ""}</span>
);

const FirmwareList: React.FC = (props) => (
  <List
    bulkActionButtons={false}
    {...props}
    sort={{ field: "version", order: "desc" }}
  >
    <Datagrid rowClick="show">
      <TextField source="version" />
      <TextField source="device_version" />
      <TextField source="type" />
      <BooleanField source="enabled" />
      <DateField label="Date created" source="created" showTime />
      <EditButton />
    </Datagrid>
  </List>
);

const FirmwareShow: React.FC = (props) => (
  <Show actions={<ShowActionsToolbar />} title={<FirmwareTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="Id" source="id" />
      <TextField source="version" />
      <TextField source="filename" />
      <TextField source="description" fullWidth />
      <UrlField source="file_url" />
      <NumberField source="file_size" />
      <TextField source="checksum" />
      <TextField source="device_version" />
      <TextField source="type" />
      <BooleanField source="enabled" />
      <ReferenceField source="created_by" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="modified_by" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <DateField label="Date created" source="created" showTime />
      <DateField label="Date modified" source="modified" showTime />
    </SimpleShowLayout>
  </Show>
);

const FirmwareCreate: React.FC = (props) => (
  <Create actions={<CreateActionsToolbar />} {...props}>
    <SimpleForm redirect="show">
      <NumberInput source="version" validate={required()} />
      <SelectInput
        source="device_version"
        choices={available_device_versions}
        defaultValue={1}
        validate={required()}
      />
      <SelectInput
        source="type"
        choices={[
          { id: "hex", name: "Hex" },
          { id: "bin", name: "Bin" },
          { id: "zip", name: "Zip" }
        ]}
        validate={required()}
      />
      <TextInput source="description" validate={required()} fullWidth />
      <FileInput
        source="firmwareFile"
        label="Upload firmware file in BIN, HEX or ZIP format"
        isRequired
      >
        <FileField source="src" title="title" />
      </FileInput>
      <BooleanInput source="enabled" defaultValue={true} />
    </SimpleForm>
  </Create>
);

const FirmwareEdit: React.FC = (props) => (
  <Edit
    actions={<EditActionsToolbar />}
    title={<FirmwareTitle />}
    mutationMode="pessimistic"
    {...props}
  >
    <SimpleForm redirect="show">
      <TextInput source="id" disabled />
      <NumberInput source="version" validate={required()} />
      <SelectInput
        source="device_version"
        choices={available_device_versions}
        defaultValue={1}
        validate={required()}
      />
      <SelectInput
        source="type"
        choices={[
          { id: "hex", name: "Hex" },
          { id: "bin", name: "Bin" },
          { id: "zio", name: "Zip" }
        ]}
        validate={required()}
        disabled
      />
      <TextInput source="description" validate={required()} fullWidth />
      <UrlField source="file_url" label="Current file url" />
      <FileInput
        source="firmwareFile"
        label="Upload firmware file in BIN, HEX or ZIP format"
        isRequired
      >
        <FileField source="src" title="title" />
      </FileInput>
      <BooleanInput source="enabled" defaultValue={true} />
    </SimpleForm>
  </Edit>
);

export default {
  list: FirmwareList,
  show: FirmwareShow,
  create: FirmwareCreate,
  edit: FirmwareEdit
};
