import React from "react";
import {
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FieldProps,
  List,
  required,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from "react-admin";
import {
  ShowActionsToolbar,
  CreateActionsToolbar,
  EditActionsToolbar,
  BGBooleanField
} from "../common";
import { MotivationType } from "./types";

interface MotivationProps extends FieldProps {
  source?: keyof MotivationType;
  record?: MotivationType;
}

const MotivationTypeTitle: React.FC<MotivationProps> = ({ record = {} }) => (
  <span>Motivation Type {record ? `"${record.name}"` : ""}</span>
);

const MotivationTypeList: React.FC = (props) => (
  <List bulkActionButtons={false} {...props} perPage={25}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="message" />
      <BGBooleanField source="enabled" />
      <DateField source="created" showTime />
      <EditButton />
    </Datagrid>
  </List>
);

const MotivationTypeShow: React.FC = (props) => (
  <Show
    actions={<ShowActionsToolbar />}
    title={<MotivationTypeTitle />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField label="Id" source="id" />
      <TextField source="name" />
      <TextField source="message" fullWidth />
      <BGBooleanField source="enabled" />
      <DateField source="created" showTime />
      <DateField source="modified" showTime />
    </SimpleShowLayout>
  </Show>
);

const DEFAULT_MESSAGE_TYPE = "MessageTypeDefault";

const MotivationTypeDefaultInfo: React.FC<unknown> = () => (
  <span style={{ color: "gray" }}>
    TIP: If you want to return generic, default messages, type{" "}
    <strong>
      <i>{DEFAULT_MESSAGE_TYPE}</i>
    </strong>{" "}
    as a name
  </span>
);

const MotivationTypeCreate: React.FC = (props) => (
  <Create actions={<CreateActionsToolbar />} {...props}>
    <SimpleForm redirect="list">
      <TextInput
        source="name"
        placeholder={DEFAULT_MESSAGE_TYPE}
        validate={required()}
        parse={(v: string) => v.replace(/([^a-zA-Z0-9])+/g, "")}
      />
      <TextInput source="message" multiline fullWidth validate={required()} />
      <BooleanInput source="enabled" defaultValue={true} />
      <MotivationTypeDefaultInfo />
    </SimpleForm>
  </Create>
);

const MotivationTypeEdit: React.FC = (props) => (
  <Edit
    actions={<EditActionsToolbar />}
    title={<MotivationTypeTitle />}
    mutationMode="pessimistic"
    {...props}
  >
    <SimpleForm redirect="list">
      <TextField label="Id" source="id" />
      <TextInput
        source="name"
        validate={required()}
        parse={(v: string) => v.replace(/([^a-zA-Z0-9])+/g, "")}
      />
      <TextInput source="message" multiline fullWidth validate={required()} />
      <BooleanInput source="enabled" defaultValue={true} />
      <TextField source="created" />
    </SimpleForm>
  </Edit>
);

export {
  MotivationTypeList,
  MotivationTypeCreate,
  MotivationTypeEdit,
  MotivationTypeShow
};
