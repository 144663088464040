/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as Sentry from "@sentry/react";

import { AuthProvider, fetchUtils } from "react-admin";
import jwt from "jsonwebtoken";
import { User } from "../users/types";

const apiUrl = process.env.REACT_APP_API_HOST + "/api/v1";

type AuthProps = {
  username: string;
  password: string;
};

const getUser = async (token: string, user_id: string): Promise<User> => {
  const options: fetchUtils.Options = { method: "GET" };
  options.user = {
    authenticated: true,
    token: `Bearer ${token}`
  };
  return fetchUtils
    .fetchJson(`${apiUrl}/users/${user_id}`, options)
    .then(({ json }) => {
      delete json.data.id;
      return { id: json.data._id, fullName: json.data.name, ...json.data };
    });
};

const authProvider: AuthProvider = {
  login: async ({ username, password }: AuthProps): Promise<void> => {
    let token;
    try {
      const { json } = await fetchUtils.fetchJson(`${apiUrl}/auth/login`, {
        method: "POST",
        body: JSON.stringify({ email: username, password, type: "admin" })
      });
      token = json.data.accessToken || "";
    } catch ({ status, body }) {
      return Promise.reject(body.error);
    }

    const decoded = jwt.decode(token, { json: true });
    if (!decoded || !decoded.id) {
      return Promise.reject("Unauthorized");
    }
    const user = await getUser(token, decoded.id);
    if (user.is_admin === true) {
      localStorage.setItem("token", token);
      localStorage.setItem("auth", JSON.stringify(user));
      return Promise.resolve();
    }

    return Promise.reject("Unauthorized");
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("auth")
      ? Promise.resolve()
      : Promise.reject({ redirectTo: "/login" });
  },
  checkError: (error: { status: number }) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject({ redirectTo: "/login" });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
  getIdentity: async () => {
    const token = localStorage.getItem("token");
    const auth = localStorage.getItem("auth");
    if (token && auth) {
      const { _id } = JSON.parse(auth);
      const user = await getUser(token, _id);
      if (user) {
        Sentry.setUser(user);
        return Promise.resolve(user);
      }
    }

    Sentry.configureScope((scope) => scope.setUser(null));

    return Promise.reject({ redirectTo: "/login" });
  }
};

export default authProvider;
