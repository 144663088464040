import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import createPalette from "@material-ui/core/styles/createPalette";
import backgroundImg from "./assets/img/background.svg";

const breakpoints = createBreakpoints({});

const palette = createPalette(
  merge({}, defaultTheme.palette, {
    primary: {
      // light: "#ec9852",
      main: "#e87f27" // Kind of orange
      // dark: "#a2581b"
      //   light: "#f6f5f2",
      //   main: "#f4f3ef", // Light gray
      //   dark: "#aaaaa7"
    },
    secondary: {
      // light: "#ec9852",
      main: "#e87f27" // Kind of orange
      // dark: "#a2581b"
    }
    // background: {
    //   //   default: "#e87f27"
    // }
  })
);

const typography = {
  fontFamilySecondary: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: 14, // Should be a number in pixels
  fontStyle: "normal",
  fontWeightLight: 400,
  fontWeightRegular: 500,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  color: palette.text.secondary
};

const typographyBase = {
  fontFamily: typography.fontFamily,
  fontSize: typography.fontSize,
  fontStyle: typography.fontStyle,
  color: typography.color
};

const typographyHeader = {
  ...typographyBase,
  fontWeight: typography.fontWeightBold,
  fontFamily: typography.fontFamilySecondary // Use a dedicated font for the header
};

const typographyBody = {
  ...typographyBase,
  fontWeight: typography.fontWeightRegular,
  fontFamily: typography.fontFamily
};

const sidebarWidth = 180;

const rawTheme = {
  palette,
  typography: {
    ...typographyBase,
    h1: {
      ...typographyHeader,
      fontSize: 20
    },
    h2: {
      ...typographyHeader,
      fontSize: 18
    },
    h3: {
      ...typographyHeader,
      fontSize: 16
    },
    body1: {
      ...typographyBody
    }
  },
  sidebar: {
    width: sidebarWidth, // The default value is 240
    closedWidth: 55 // The default value is 55
  },
  overrides: {
    MuiButton: {
      root: {
        color: palette.primary.main
      },
      sizeSmall: {},
      sizeLarge: {},
      contained: {
        boxShadow: "none"
      },
      containedPrimary: {
        color: palette.common.white,
        backgroundColor: palette.primary.main
      },
      containedSecondary: {
        color: palette.common.white,
        backgroundColor: palette.secondary.main
      }
    },
    RaDatagrid: {
      headerCell: {
        fontWeight: "bold"
      }
    },
    RaLayout: {
      content: {
        // padding: "16px",
        // "@media (min-width: 600px)": {
        [breakpoints.up("sm")]: {
          paddingLeft: "24px"
        },
        backgroundColor: "#eeeeee",
        backgroundImage: `url(${backgroundImg})`,
        backgroundPositionY: "-50px"
      }
    },
    RaSidebar: {
      drawerPaper: {
        backgroundColor: palette.common.white,
        color: palette.secondary.main,
        height: "100%",
        boxShadow:
          "2px 0px 1px -1px rgba(0,0,0,0.2), 1px 0px 3px 0px rgba(0,0,0,0.1)"
      }
    },
    RaMenu: {
      open: {
        width: `${sidebarWidth}px`
      }
    },
    RaMenuItemLink: {
      root: {
        fontSize: "16px",
        lineHeight: 2
      },
      active: {
        color: palette.secondary.main,
        fontWeight: "bold"
      },
      icon: {
        color: "inherit"
      }
    },
    RaTabbedShowLayout: {
      content: {
        padding: "8px 16px 16px",
        "@media (min-width: 600px)": {
          padding: "24px"
        }
      }
    },
    RaChipField: {
      chip: {
        fontSize: "11px",
        color: "#fff",
        backgroundColor: palette.primary.main,
        padding: "4px 0",
        "&:hover": {
          backgroundColor: palette.primary.dark
        }
      }
    }
  }
};

export const theme = createMuiTheme(merge({}, defaultTheme, rawTheme));
