import React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  UrlField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  required,
  EditButton,
  FieldProps,
  Show,
  SimpleShowLayout,
  NumberField,
  NumberInput
} from "react-admin";
import {
  CreateActionsToolbar,
  EditActionsToolbar,
  ShowActionsToolbar
} from "../common";
import { validateUrl } from "../validators";

export interface Tutorial {
  id: string;
  url: string;
  description: string;
  image: string;
  order: number;
  created: Date;
  modified: Date;
}

interface TutorialProps extends FieldProps {
  source?: keyof Tutorial;
  record?: Tutorial;
}

const TutorialTitle: React.FC<TutorialProps> = ({ record = {} }) => (
  <span>Tutorial {record ? `"${record.description}"` : ""}</span>
);

const TutorialList: React.FC = (props) => (
  <List
    bulkActionButtons={false}
    {...props}
    sort={{ field: "order", order: "ASC" }}
  >
    <Datagrid>
      <NumberField source="order" />
      <TextField source="description" />
      <UrlField source="image" />
      <UrlField source="url" />
      <DateField label="Last modified" source="modified" showTime />
      <EditButton />
    </Datagrid>
  </List>
);

const TutorialShow: React.FC = (props) => (
  <Show actions={<ShowActionsToolbar />} title={<TutorialTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="Id" source="id" />
      <NumberField source="order" />
      <TextField source="description" />
      <UrlField fullWidth source="image" />
      <UrlField fullWidth source="url" />
      <DateField source="created" showTime />
      <DateField source="modified" showTime />
    </SimpleShowLayout>
  </Show>
);

const TutorialCreate: React.FC = (props) => (
  <Create actions={<CreateActionsToolbar />} {...props}>
    <SimpleForm redirect="list">
      <TextInput fullWidth source="description" validate={required()} />
      <TextInput fullWidth source="image" validate={validateUrl} />
      <TextInput source="url" validate={validateUrl} />
      <NumberInput
        source="order"
        min={0}
        max={100}
        defaultValue={0}
        validate={required()}
      />
    </SimpleForm>
  </Create>
);

const TutorialEdit: React.FC = (props) => (
  <Edit
    actions={<EditActionsToolbar />}
    title={<TutorialTitle />}
    mutationMode="pessimistic"
    {...props}
  >
    <SimpleForm redirect="list">
      <TextInput disabled label="Id" source="_id" />
      <TextInput fullWidth source="description" validate={required()} />
      <TextInput fullWidth source="image" validate={validateUrl} />
      <TextInput source="url" validate={validateUrl} />
      <NumberInput
        source="order"
        min={0}
        max={100}
        defaultValue={0}
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);

export default {
  list: TutorialList,
  show: TutorialShow,
  create: TutorialCreate,
  edit: TutorialEdit
};
