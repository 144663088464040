import React, { useEffect, useState } from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  Edit,
  FormDataConsumer,
  Labeled,
  LinearProgress,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextInput,
  useQueryWithStore
} from "react-admin";
import { useField } from "react-final-form";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import intersectionWith from "lodash/intersectionWith";
import differenceBy from "lodash/differenceBy";
import uniqBy from "lodash/uniqBy";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableLocation
} from "react-beautiful-dnd";

import { EditActionsToolbar } from "../common";
import { WorkoutCategory } from "./types";

const reorder = (
  list: Iterable<WorkoutCategory> | ArrayLike<WorkoutCategory>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (
  source: Iterable<unknown> | ArrayLike<unknown>,
  destination: Iterable<unknown> | ArrayLike<unknown>,
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: Record<string, unknown> = {};
  result[droppableSource.droppableId] = uniqBy(sourceClone, "id");
  result[droppableDestination.droppableId] = uniqBy(destClone, "id");

  return result;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 752
    },
    title: {
      fontSize: "1.2em",
      fontWeight: "bold",
      margin: theme.spacing(0, 0, 0)
    },
    listItem: {},
    listItemDragging: {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    },
    list: {
      paddingBottom: "16px",
      minHeight: "150px",
      maxHeight: "300px",
      overflowY: "auto"
    },
    listIsDraggingOver: {
      paddingBottom: "16px",
      minHeight: "150px",
      maxHeight: "300px",
      overflowY: "auto",
      border: "1px solid lightgray"
    }
  })
);

const DraggableRow: React.FC<{
  item: WorkoutCategory;
  index: number;
}> = (props) => {
  const { item, index } = props;
  const classes = useStyles();

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={
            snapshot.isDragging ? classes.listItemDragging : classes.listItem
          }
        >
          {item.name}
        </ListItem>
      )}
    </Draggable>
  );
};

const SubcategoriesList: React.FC<any> = ({
  choices,
  label,
  resource,
  source,
  className
}) => {
  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "workout-categories",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "ASC" },
      filter: { is_parent: false }
    }
  });

  const { input } = useField(source);
  const classes = useStyles();

  const [subcategories, setSubcategories] = useState(
    [] as unknown as WorkoutCategory[]
  );
  const [availableCategories, setAvailableSubcategories] = useState(
    [] as unknown as WorkoutCategory[]
  );

  const id2List = (id: string) => {
    if (id == "droppableSubcategories") {
      return subcategories;
    }
    return availableCategories;
  };

  const updateSubcategories = (list: WorkoutCategory[]) => {
    const ids = list.map(({ id }) => id);
    input.onChange(ids);
    setSubcategories(list);
  };

  const onDragEnd = async (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId == destination.droppableId) {
      // Allow ordering of subcategories only
      if (source.droppableId != "droppableSubcategories") {
        return;
      }
      const reordered = reorder(subcategories, source.index, destination.index);
      updateSubcategories(reordered);
    } else {
      const result = move(
        id2List(source.droppableId),
        id2List(destination.droppableId),
        source,
        destination
      );

      updateSubcategories(
        result.droppableSubcategories as unknown as WorkoutCategory[]
      );

      setAvailableSubcategories(
        result.droppableAvailableSubcategories as unknown as WorkoutCategory[]
      );
    }
  };

  useEffect(() => {
    if (!subcategories.length) {
      const selectedSubcategories: WorkoutCategory[] = intersectionWith(
        choices,
        input.value,
        (el: WorkoutCategory, num) => el.id == num
      );
      setSubcategories(selectedSubcategories);
    }
  }, [choices, input.value]);

  useEffect(() => {
    setAvailableSubcategories(
      differenceBy(data, subcategories, "id") as unknown as WorkoutCategory[]
    );
  }, [data, input.value]);

  if (loading) {
    return (
      <Labeled
        label={label}
        source={source}
        resource={resource}
        className={className}
      >
        <LinearProgress />
      </Labeled>
    );
  }

  if (!choices) return null;

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid item xs={12} sm={5} md={3} style={{ minWidth: "330px" }}>
            <Typography variant="h6" className={classes.title}>
              Current subcategories
            </Typography>
            <Droppable droppableId="droppableSubcategories">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ overflow: "hidden" }}
                >
                  <List
                    className={
                      snapshot.isDraggingOver
                        ? classes.listIsDraggingOver
                        : classes.list
                    }
                  >
                    {subcategories.length > 0 &&
                      subcategories.map((item, index: number) => (
                        <DraggableRow key={item.id} item={item} index={index} />
                      ))}
                    {provided.placeholder}
                  </List>
                </div>
              )}
            </Droppable>
          </Grid>
          <Grid item>
            <Typography variant="h6" className={classes.title}>
              Available subcategories
            </Typography>
            <Droppable droppableId="droppableAvailableSubcategories">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ overflow: "hidden" }}
                >
                  <List
                    className={
                      snapshot.isDraggingOver
                        ? classes.listIsDraggingOver
                        : classes.list
                    }
                  >
                    {availableCategories &&
                      availableCategories.map((item, index: number) => (
                        <DraggableRow key={item.id} item={item} index={index} />
                      ))}
                    {provided.placeholder}
                  </List>
                </div>
              )}
            </Droppable>
          </Grid>
        </DragDropContext>
      </Grid>
    </div>
  );
};

const WorkoutCategoryEdit: React.FC<any> = ({ hasList, hasShow, ...rest }) => {
  return (
    <Edit actions={<EditActionsToolbar />} mutationMode="pessimistic" {...rest}>
      <SimpleForm redirect="show">
        <TextInput disabled source="id" />
        <TextInput source="name" validate={required()} />
        <TextInput
          multiline
          fullWidth
          source="description"
          validate={required()}
        />

        <FormDataConsumer>
          {({ formData }) => (
            <Labeled label="Category type">
              <div style={{ fontWeight: "bold" }}>
                {formData.is_parent ? "Main category" : "Subcategory"}
              </div>
            </Labeled>
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) => (
            <RadioButtonGroupInput
              label="Change category type"
              source="is_parent"
              disabled={
                formData.workouts?.length > 0 ||
                formData.subcategories?.length > 0
              }
              choices={[
                { id: true, name: "Main category" },
                { id: false, name: "Subcategory" }
              ]}
            />
          )}
        </FormDataConsumer>

        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) => (
            <>
              {formData.is_parent === true && (
                <ReferenceArrayInput
                  source="subcategories"
                  reference="workout-categories"
                  filter={{ is_parent: false }}
                  perPage={100}
                >
                  <SubcategoriesList />
                </ReferenceArrayInput>
              )}
              {formData.is_parent === false && (
                <>
                  <ReferenceArrayInput
                    {...rest}
                    source="workouts"
                    reference="workouts"
                    perPage={100}
                    allowEmpty
                  >
                    <AutocompleteArrayInput />
                  </ReferenceArrayInput>
                  <div></div>
                  <ReferenceArrayInput
                    {...rest}
                    label="Main category"
                    source="parents"
                    reference="workout-categories"
                    filter={{ is_parent: true }}
                    perPage={100}
                    allowEmpty
                  >
                    <AutocompleteArrayInput />
                  </ReferenceArrayInput>
                </>
              )}
            </>
          )}
        </FormDataConsumer>
        <BooleanInput source="enabled" defaultValue={true} />
      </SimpleForm>
    </Edit>
  );
};

export default WorkoutCategoryEdit;
