/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  BooleanField,
  DeleteButton,
  EditActionsProps,
  EditButton,
  FieldProps,
  ListButton,
  SelectInput,
  ShowActionsProps,
  ShowButton,
  Title,
  TitleForRecord,
  TopToolbar,
  useShowContext
} from "react-admin";
import { Card as MuiCard, CardContent, withStyles } from "@material-ui/core";

import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Done from "@material-ui/icons/Done";
import Clear from "@material-ui/icons/Clear";

export const ShowActionsToolbar: React.FC<ShowActionsProps> = ({
  basePath,
  hasList,
  hasEdit,
  children,
  ...rest
}) => {
  const { record } = useShowContext(rest);
  return (
    <TopToolbar {...rest}>
      {children && (
        <span
          style={{
            display: "inline-flex",
            flexGrow: 1,
            fontSize: "1.2rem",
            paddingLeft: "8px"
          }}
        >
          {children}
        </span>
      )}
      <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
      <EditButton record={record} />
      <DeleteButton basePath={basePath} record={record} undoable={false} />
    </TopToolbar>
  );
};

export const EditActionsToolbar: React.FC<EditActionsProps> = ({
  basePath,
  hasList,
  hasShow,
  children,
  ...rest
}) => {
  const { record } = useShowContext(rest);
  return (
    <TopToolbar {...rest}>
      {children && (
        <span
          style={{
            display: "inline-flex",
            flexGrow: 1,
            fontSize: "1.2rem",
            paddingLeft: "8px"
          }}
        >
          {children}
        </span>
      )}
      <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
      <ShowButton basePath={basePath} record={record} />
      <DeleteButton basePath={basePath} record={record} undoable={false} />
    </TopToolbar>
  );
};

export const CreateActionsToolbar: React.FC<EditActionsProps> = ({
  basePath,
  hasList,
  hasShow,
  ...rest
}) => {
  const { record } = useShowContext(rest);
  return (
    <TopToolbar {...rest}>
      <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
    </TopToolbar>
  );
};

const SideCard = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "15em",
      marginRight: "1em"
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}))(MuiCard);

export const SideCardFilter: React.FC = (props) => {
  const { children } = props;
  return (
    <SideCard>
      <CardContent>{children}</CardContent>
    </SideCard>
  );
};

export const TrueIcon: any = () => <Done style={{ color: "green" }} />;
export const FalseIcon: any = () => <Clear style={{ color: "red" }} />;

export const BGBooleanField: React.FC<FieldProps> = (props) => (
  <BooleanField TrueIcon={TrueIcon} FalseIcon={FalseIcon} {...props} />
);

// interface UniqueSelectInputProps {
//   choices?: [];
//   source?: string;
// }

// export const UniqueSelectInput = (props: UniqueSelectInputProps) => {
//   const { choices, source } = props;
//   console.log(source, choices);

//   let choices_unique: Array<{ name: string; id: string }> = [];
//   if (choices && source) {
//     const choices_unique_list = choices
//       .map((item) => item[source])
//       .filter((value, index, self) => self.indexOf(value) === index);
//     choices_unique = choices_unique_list.map((item) => {
//       return { name: item, id: item };
//     });
//   }
//   return (
//     <>
//       <SelectInput choices={choices_unique} source={source} />
//     </>
//   );
// };
