import React, { useEffect } from "react";
import {
  EditButton,
  ListActions,
  ListController,
  ListToolbar,
  Loading,
  ShowButton,
  Title,
  useMutation,
  useQueryWithStore,
  useNotify,
  Button
} from "react-admin";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import pick from "lodash/pick";
import toPairs from "lodash/toPairs";
import values from "lodash/values";
import map from "lodash/map";
import merge from "lodash/merge";

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from "react-beautiful-dnd";

import { FalseIcon, TrueIcon } from "../common";
import { WorkoutCategory } from "./types";

const styles = makeStyles((theme) =>
  createStyles({
    root: {
      // width: "100%",
      marginTop: theme.spacing(3)
    },
    table: {
      minWidth: 1020
    },
    tableWrapper: {
      overflowX: "auto"
    }
  })
);

const colsToRender = ["order", "name", "subcategories", "enabled", "modified"];

const reorder = (
  categories: WorkoutCategory[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(categories);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.map((el, index) => (el.order = index));

  return result;
};

interface SubcategoriesListProps {
  ids: string[];
}

const SubcategoriesList: React.FC<SubcategoriesListProps> = ({ ids }) => {
  const { data, loading, error } = useQueryWithStore({
    type: "getList",
    resource: "workout-categories",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "order", order: "ASC" },
      filter: { id: ids }
    }
  });

  if (loading) return <Loading />;
  if (error) {
    return <p>ERROR</p>;
  }

  if (!data) return null;

  return (
    <Table size="small" aria-label="subcategories">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align={"center"}>Workouts</TableCell>
          <TableCell align={"center"}>Enabled</TableCell>
          <TableCell align={"center"} colSpan={2}>
            Actions
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((el: WorkoutCategory) => (
          <TableRow key={el.id}>
            <TableCell component="th" scope="row">
              {el.name}
            </TableCell>
            <TableCell align={"center"}>{el.workouts?.length}</TableCell>
            <TableCell align={"center"}>
              {el.enabled ? <TrueIcon /> : <FalseIcon />}
            </TableCell>
            <TableCell align={"center"}>
              <ShowButton
                basePath="/workout-categories"
                label="Show"
                record={el}
                style={{ marginRight: "8px" }}
              />
              <EditButton
                basePath="/workout-categories"
                label="Edit"
                record={el}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const CategoryRow: React.FC<{
  item: WorkoutCategory;
  index: number;
}> = ({ item, index }) => {
  const cellData = pick(item, colsToRender);

  const [open, setOpen] = React.useState(false);

  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided) => (
        <>
          <TableRow
            hover
            tabIndex={-1}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            {toPairs(cellData).map((el) => {
              if (el[1] === true) {
                return (
                  <TableCell key={el[0]}>
                    <TrueIcon />
                  </TableCell>
                );
              } else if (el[1] === false) {
                return (
                  <TableCell key={el[0]}>
                    <FalseIcon />
                  </TableCell>
                );
              }
              if (el[0] == "subcategories") {
                return (
                  <TableCell key={el[0]} align={"center"}>
                    {el[1].length}
                  </TableCell>
                );
              }
              return <TableCell key={el[0]}>{el[1]}</TableCell>;
            })}
            <TableCell>
              <ShowButton
                basePath="/workout-categories"
                label="Show"
                record={item}
                style={{ marginRight: "8px" }}
              />
              <EditButton
                basePath="/workout-categories"
                label="Edit"
                record={item}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{ paddingBottom: 0, paddingTop: 0 }}
              colSpan={colsToRender.length + 2}
            >
              <Collapse in={open} timeout="auto" unmountOnExit={false}>
                <Box margin={1}>
                  <SubcategoriesList
                    ids={item.subcategories as unknown as string[]}
                  />
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </Draggable>
  );
};

const CategoriesList: React.FC<any> = ({ data, loading, error, ...rest }) => {
  const classes = styles();
  const [categories, setCategories] = React.useState<WorkoutCategory[]>(
    values(data || [])
  );
  const [canBeUpdated, setCanBeUpdated] = React.useState(false);

  const notify = useNotify();

  useEffect(() => {
    if (data) {
      setCategories(values(data));
      setCanBeUpdated(false);
    }
  }, [data]);

  const [update] = useMutation();

  const updateAll = (categories: WorkoutCategory[]) => {
    const ids = map(categories, "id");
    const data = map(categories, (el) => pick(el, ["id", "order", "name"]));
    return update(
      {
        type: "updateMany",
        resource: "workout-categories",
        payload: { ids, data }
      },
      {
        onSuccess: ({ data }) => {
          notify("Categories succesfully updated");
          setCategories(merge(categories, data));
          setCanBeUpdated(false);
        },
        onFailure: (error) => {
          console.error("updateAll", error.status, error.message);
          notify(error.message, "warning");
          setCanBeUpdated(false);
        }
      }
    );
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }
    setCanBeUpdated(true);

    const _categories = reorder(
      categories,
      result.source.index,
      result.destination.index
    );
    setCategories(_categories);
  };

  if (loading) return <Loading />;
  if (error) {
    return <p>ERROR</p>;
  }

  if (!categories) return null;

  return (
    <Table
      table-layout="fixed"
      className={classes.table}
      aria-labelledby="tableTitle"
    >
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          {colsToRender.map((el, index) => (
            <TableCell
              key={index}
              style={{ fontWeight: "bold", textTransform: "capitalize" }}
            >
              {el}
            </TableCell>
          ))}
          <TableCell
            colSpan={2}
            align={"center"}
            style={{ fontWeight: "bold", textTransform: "capitalize" }}
          >
            Actions
          </TableCell>
        </TableRow>
      </TableHead>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <TableBody ref={provided.innerRef} {...provided.droppableProps}>
              {categories.map((item: WorkoutCategory, index: number) => (
                <CategoryRow key={item.id} item={item} index={index} />
              ))}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
      <TableRow>
        <TableCell colSpan={colsToRender.length + 1}>
          <Button
            style={{ marginTop: "1em" }}
            onClick={() => updateAll(categories)}
            label="Update categories"
            disabled={!canBeUpdated}
          />
        </TableCell>
      </TableRow>
    </Table>
  );
};

const CategoriesListView: React.FC = (props) => {
  const classes = styles();

  return (
    <ListController
      filter={{ is_parent: true }}
      sort={{ field: "order", order: "ASC" }}
      perPage={100}
      {...props}
    >
      {(ListControllerProps) => (
        <>
          <Title title="Categories" />
          <ListToolbar actions={<ListActions />} />
          <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
              <CategoriesList {...ListControllerProps} />
            </div>
          </Paper>
        </>
      )}
    </ListController>
  );
};

export default CategoriesListView;
