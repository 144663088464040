import * as React from "react";
import { AppBar, Layout, Sidebar } from "react-admin";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import LogoImageLarge from "./assets/img/logo.png";
import LogoImageSquare from "./assets/img/logo-square.svg";
import { theme } from "./theme";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  spacer: {
    flex: 1
  },
  logo_large: {
    maxWidth: "80%",
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px ${theme.spacing(
      2
    )}px`
  },
  logo_small: {
    maxWidth: "65%",
    margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px ${theme.spacing(
      1
    )}px`
  }
});

const useSidebarStyles = makeStyles({
  drawerPaper: {
    backgroundColor: theme.palette.common.white
  }
});

const Logo: React.FC<{ sidebar_open: boolean }> = ({ sidebar_open = true }) => {
  const classes = useStyles();
  return (
    <img
      src={sidebar_open ? LogoImageLarge : LogoImageSquare}
      alt="BionicGym logo"
      className={sidebar_open ? classes.logo_large : classes.logo_small}
    />
  );
};

const BGAppBar: React.FC = (props) => {
  const classes = useStyles();
  return (
    <AppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
    </AppBar>
  );
};

const BGSidebar: React.FC = ({ children, ...props }) => {
  const classes = useSidebarStyles();
  const open = useSelector(
    (state: any) => state.admin.ui.sidebarOpen
  ) as boolean;
  return (
    <Sidebar classes={classes} {...props}>
      <>
        <Logo sidebar_open={open} />
        {children}
      </>
    </Sidebar>
  );
};

const BGLayout: React.FC = (props) => (
  <Layout {...props} sidebar={BGSidebar} appBar={BGAppBar} />
);

export default BGLayout;
