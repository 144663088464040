import React from "react";
import {
  AutocompleteArrayInput,
  BooleanInput,
  CreateController,
  CreateView,
  Datagrid,
  DateField,
  EditButton,
  FieldProps,
  FormDataConsumer,
  FunctionField,
  Labeled,
  NumberField,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  required,
  ShowButton,
  ShowController,
  ShowView,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput
} from "react-admin";
import {
  ShowActionsToolbar,
  CreateActionsToolbar,
  BGBooleanField
} from "../common";
import { WorkoutCategory } from "./types";

interface WorkoutCategoryProps extends FieldProps {
  source?: keyof WorkoutCategory;
  record?: WorkoutCategory;
}

const CategoryTitle: React.FC<WorkoutCategoryProps> = ({ record }) => (
  <>
    {record?.is_parent ? "Category" : "Subcategory"}
    {": "}
    {record ? `"${record.name}"` : ""}
  </>
);
const ToolbarTitle: React.FC<WorkoutCategoryProps> = ({ record }) => (
  <>{record ? `${record.name}` : ""}</>
);

const WorkoutCategoryShow: React.FC = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView
        {...controllerProps}
        actions={
          <ShowActionsToolbar>
            <ToolbarTitle
              record={controllerProps.record as unknown as WorkoutCategory}
            />
          </ShowActionsToolbar>
        }
        title={<CategoryTitle />}
      >
        <SimpleShowLayout>
          <TextField label="Id" source="id" />
          <TextField source="name" />
          <TextField fullWidth source="description" />
          <Labeled label="Category type">
            <div>
              {controllerProps.record?.is_parent
                ? "Main category"
                : "Subcategory"}
            </div>
          </Labeled>

          {controllerProps.record?.is_parent ? (
            <ReferenceArrayField
              source="subcategories"
              reference="workout-categories"
            >
              <Datagrid>
                <TextField source="name" />
                <BGBooleanField source="enabled" />
                <FunctionField
                  label="Workouts"
                  render={(record: any) =>
                    record && record.workouts?.length && record.workouts?.length
                  }
                />
                <ShowButton />
                <EditButton />
              </Datagrid>
            </ReferenceArrayField>
          ) : (
            <div style={{ maxWidth: "700px" }}>
              <Labeled label="Workouts">
                <ReferenceArrayField
                  {...controllerProps}
                  source="workouts"
                  reference="workouts"
                  label="Subcategories"
                  addLabel
                >
                  <Datagrid>
                    <TextField source="name" />
                    <BGBooleanField source="enabled" />
                    <NumberField source="duration" />
                    <ShowButton />
                    <EditButton />
                  </Datagrid>
                </ReferenceArrayField>
              </Labeled>
              <Labeled label="Parents">
                <ReferenceArrayField
                  {...controllerProps}
                  source="parents"
                  reference="workout-categories"
                >
                  <Datagrid>
                    <TextField source="name" />
                    <BGBooleanField source="enabled" />
                    <ShowButton />
                    <EditButton />
                  </Datagrid>
                </ReferenceArrayField>
              </Labeled>
            </div>
          )}

          <Labeled label="Enabled">
            <BGBooleanField source="enabled" />
          </Labeled>
          <ReferenceField source="created_by" reference="users">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="created" showTime />
          <DateField source="modified" showTime />
        </SimpleShowLayout>
      </ShowView>
    )}
  </ShowController>
);

const WorkoutCategoryCreate: React.FC = (props) => (
  <CreateController {...props}>
    {(controllerProps) => (
      <CreateView {...controllerProps} actions={<CreateActionsToolbar />}>
        <SimpleForm redirect="list">
          <TextInput fullWidth source="name" validate={required()} />
          <TextInput
            multiline
            fullWidth
            source="description"
            validate={required()}
          />
          <RadioButtonGroupInput
            label="Category type"
            source="is_parent"
            choices={[
              { id: true, name: "Main category" },
              { id: false, name: "Subcategory" }
            ]}
          />
          <FormDataConsumer subscription={{ values: true }}>
            {({ formData, ...rest }) => (
              <>
                {formData.is_parent === true && (
                  <ReferenceArrayInput
                    {...rest}
                    source="subcategories"
                    reference="workout-categories"
                    filter={{ is_parent: false }}
                    perPage={100}
                    allowEmpty
                  >
                    <AutocompleteArrayInput />
                  </ReferenceArrayInput>
                )}
                {formData.is_parent === false && (
                  <>
                    <ReferenceArrayInput
                      {...rest}
                      source="workouts"
                      reference="workouts"
                      perPage={100}
                    >
                      <AutocompleteArrayInput />
                    </ReferenceArrayInput>
                    <div></div>
                    <ReferenceArrayInput
                      {...rest}
                      label="Main categories"
                      source="parents"
                      reference="workout-categories"
                      filter={{ is_parent: true }}
                      perPage={100}
                    >
                      <AutocompleteArrayInput />
                    </ReferenceArrayInput>
                  </>
                )}
              </>
            )}
          </FormDataConsumer>
          <NumberInput
            source="order"
            min={0}
            max={100}
            defaultValue={50}
            validate={required()}
          />
          <BooleanInput source="enabled" defaultValue={true} />
        </SimpleForm>
      </CreateView>
    )}
  </CreateController>
);

export { WorkoutCategoryCreate, WorkoutCategoryShow };
