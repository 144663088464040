import { Admin, Resource } from "react-admin";
import authProvider from "./providers/authProvider";
import dataProvider from "./providers/dataProvider";
import Users from "./users";
import Promocodes from "./promocodes";
import Packages from "./packages";
import Tutorials from "./tutorials";
import Faqs from "./faqs";
import Dashboard from "./dashboard";
import Feedbacks from "./feedbacks";
import Firmware from "./firmware";
import { Motivations, MotivationTypes } from "./motivations";

import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

import { Workouts, WorkoutCategories } from "./workouts";
import React from "react";
import { theme } from "./theme";
import BGLayout from "./layout";
import BGLoginPage from "./pages/LoginPage";

import SvgIcon from "@material-ui/core/SvgIcon";
import MemoryIcon from "@material-ui/icons/Memory";
import FeedbackIcon from "@material-ui/icons/Feedback";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import { ReactComponent as GroupUsersIcon } from "./assets/img/group-users.svg";
import { ReactComponent as ListOfTreatmentsSvg } from "./assets/img/list-of-treatments.svg";
import { ReactComponent as CategoriesSvg } from "./assets/img/treatments.svg";
import { ReactComponent as TutorialsSvg } from "./assets/img/standard-user.svg";
import { ReactComponent as FaqsSvg } from "./assets/img/medical-app.svg";

const UsersIcon: React.FC = (props) => (
  <SvgIcon component={GroupUsersIcon} viewBox="0 0 43.868 43.869" {...props} />
);

const WorkoutsIcon: React.FC = (props) => (
  <SvgIcon component={ListOfTreatmentsSvg} viewBox="0 0 512 512" {...props} />
);

const CategoriesIcon: React.FC = (props) => (
  <SvgIcon component={CategoriesSvg} viewBox="0 0 474.474 474.474" {...props} />
);

const TutorialsIcon: React.FC = (props) => (
  <SvgIcon component={TutorialsSvg} viewBox="0 0 61.858 61.858" {...props} />
);
const FaqsIcon: React.FC = (props) => (
  <SvgIcon component={FaqsSvg} viewBox="0 0 512.001 512.001" {...props} />
);

const App: React.FC = (props) => (
  <Admin
    theme={theme}
    layout={BGLayout}
    title="BionicGym Admin Panel"
    disableTelemetry
    loginPage={BGLoginPage}
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}
    {...props}
  >
    <Resource
      name="users"
      options={{ label: "Users" }}
      {...Users}
      icon={UsersIcon}
    />
    <Resource
      name="workout-categories"
      options={{ label: "Categories" }}
      {...WorkoutCategories}
      icon={CategoriesIcon}
    />
    <Resource
      name="workouts"
      options={{ label: "Workouts" }}
      {...Workouts}
      icon={WorkoutsIcon}
    />
    <Resource
      name="packages"
      options={{ label: "Packages" }}
      {...Packages}
      icon={LockOutlinedIcon}
    />
    <Resource
      name="promocodes"
      options={{ label: "Promocodes" }}
      {...Promocodes}
      icon={ShoppingCartOutlinedIcon}
    />
    <Resource
      name="tutorials"
      options={{ label: "Tutorials" }}
      {...Tutorials}
      icon={TutorialsIcon}
    />
    <Resource
      name="faqs"
      options={{ label: "F.A.Q." }}
      {...Faqs}
      icon={FaqsIcon}
    />
    <Resource
      name="feedbacks"
      options={{ label: "Feedbacks" }}
      {...Feedbacks}
      icon={FeedbackIcon}
    />
    <Resource
      name="firmware"
      options={{ label: "Firmware" }}
      {...Firmware}
      icon={MemoryIcon}
    />
    <Resource
      name="motivations"
      options={{ label: "Motivations" }}
      {...Motivations}
      icon={TextsmsOutlinedIcon}
    />
    <Resource
      name="motivation-types"
      options={{ label: "Motivation Types" }}
      {...MotivationTypes}
      icon={TextsmsOutlinedIcon}
    />
  </Admin>
);

export default App;
