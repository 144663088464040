import * as React from "react";

import {
  Button,
  Filter,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  TextInput
} from "react-admin";
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  endOfMonth,
  subMonths
} from "date-fns";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import { SideCardFilter } from "../common";

const UserTypeFilter: React.FC = (props) => (
  <FilterList
    label="Type"
    icon={<SupervisedUserCircleOutlinedIcon />}
    {...props}
  >
    <FilterListItem label="Admin" value={{ is_admin: true }} />
    <FilterListItem label="Standard" value={{ is_admin: false }} />
  </FilterList>
);

const UserEnabledFilter: React.FC = (props) => (
  <FilterList
    label="Is Enabled"
    icon={<SupervisedUserCircleOutlinedIcon />}
    {...props}
  >
    <FilterListItem label="Enabled" value={{ enabled: true }} />
    <FilterListItem label="Disabled" value={{ enabled: false }} />
  </FilterList>
);

const UserIsMigrated: React.FC = (props) => (
  <FilterList
    label="Is Migrated"
    icon={<SupervisedUserCircleOutlinedIcon />}
    {...props}
  >
    <FilterListItem label="Migrated" value={{ packages: { $exists: true } }} />
    <FilterListItem
      label="Not migrated"
      value={{ packages: { $exists: false } }}
    />
  </FilterList>
);

const LastLoggedInFilter: React.FC = (props) => {
  const today = new Date();
  return (
    <FilterList label="Last logged in" icon={<AccessTimeIcon />} {...props}>
      <FilterListItem
        label="Today"
        value={{
          last_login: {
            $gte: endOfYesterday().toISOString()
          }
        }}
      />
      <FilterListItem
        label="This week"
        value={{
          last_login: {
            $gte: startOfWeek(today).toISOString()
          }
        }}
      />
      <FilterListItem
        label="Last week"
        value={{
          last_login: {
            $gte: subWeeks(startOfWeek(today), 1).toISOString(),
            $lte: startOfWeek(today).toISOString()
          }
        }}
      />
      <FilterListItem
        label="This month"
        value={{
          last_login: {
            $gte: startOfMonth(today).toISOString(),
            $lte: endOfMonth(today).toISOString()
          }
        }}
      />
      <FilterListItem
        label="Last month"
        value={{
          last_login: {
            $gte: subMonths(startOfMonth(today), 1).toISOString(),
            $lte: startOfMonth(today).toISOString()
          }
        }}
      />
      <FilterListItem
        label="Earlier"
        value={{
          last_login: {
            $lte: subMonths(startOfMonth(today), 1).toISOString()
          }
        }}
      />
    </FilterList>
  );
};

export const FilterSidebar: React.FC<any> = ({ setFilters }) => {
  return (
    <SideCardFilter>
      <FilterLiveSearch source="name" />
      <UserTypeFilter />
      <UserEnabledFilter />
      <UserIsMigrated />
      <LastLoggedInFilter />
      <Button
        style={{ marginTop: "2em" }}
        onClick={() => setFilters({})}
        label="Clear filters"
      />
    </SideCardFilter>
  );
};

export const FilterTop: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" />
  </Filter>
);
