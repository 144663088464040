import React from "react";
import {
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  FieldProps,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput
} from "react-admin";
import {
  ShowActionsToolbar,
  CreateActionsToolbar,
  EditActionsToolbar
} from "../common";
import { WorkoutCategory } from "../workouts/types";

export interface Package {
  id: string;
  name: string;
  categories: Array<string | WorkoutCategory>;
  created: Date;
  modified: Date;
}

interface PackageProps extends FieldProps {
  source?: keyof Package;
  record?: Package;
}

const PackageTitle: React.FC<PackageProps> = ({ record = {} }) => (
  <span>Package {record ? `"${record.name}"` : ""}</span>
);

const PackageList: React.FC = (props) => (
  <List bulkActionButtons={false} {...props}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <ReferenceArrayField
        label="Categories"
        reference="workout-categories"
        source="categories"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="created" showTime />
      <DateField source="modified" showTime />
      <EditButton />
    </Datagrid>
  </List>
);

const PackageShow: React.FC = (props) => (
  <Show actions={<ShowActionsToolbar />} title={<PackageTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="Id" source="id" />
      <TextField source="name" />
      <ReferenceArrayField
        label="Categories"
        reference="workout-categories"
        source="categories"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="created" showTime />
      <DateField source="modified" showTime />
    </SimpleShowLayout>
  </Show>
);

const PackageCreate: React.FC = (props) => (
  <Create actions={<CreateActionsToolbar />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" validate={required()} />
      <ReferenceArrayInput
        source="categories"
        reference="workout-categories"
        filter={{ is_parent: true }}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

const PackageEdit: React.FC = (props) => (
  <Edit
    actions={<EditActionsToolbar />}
    title={<PackageTitle />}
    mutationMode="pessimistic"
    {...props}
  >
    <SimpleForm redirect="list">
      <TextInput disabled source="id" />
      <TextInput source="name" validate={required()} />
      <ReferenceArrayInput
        source="categories"
        reference="workout-categories"
        filter={{ is_parent: true }}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <DateTimeInput disabled source="created" />
    </SimpleForm>
  </Edit>
);

export default {
  list: PackageList,
  create: PackageCreate,
  edit: PackageEdit,
  show: PackageShow
};
