import { required, regex, email, maxLength, minLength } from "react-admin";

const validateUrl = [
  required(),
  regex(/^https:\/\//i, "Urls must begin with https://"),
  regex(
    /^https:\/\/[\w\d.-]+.[\w\d]{2,4}\/[\w\d.-?=&]+/i,
    "Urls must have a valid domain name, ex. https://example.com/..."
  )
];

const validatePassword = [
  required(),
  minLength(8),
  maxLength(30),
  regex(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]+$/,
    "At least one uppercase letter and one number"
  )
];

const validateEmail = [required(), email()];

const validatePromoCode = [
  required(),
  minLength(6),
  maxLength(30),
  regex(
    /^[a-zA-Z0-9-_]+$/,
    "Only alpahnumerical characters, digits and _- are allowed"
  )
];

const validateDescription = [required(), minLength(10), maxLength(500)];

export {
  validateUrl,
  validatePassword,
  validateEmail,
  validatePromoCode,
  validateDescription
};
