import React, { useEffect, useState } from "react";

import uniq from "lodash/uniq";

import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FieldProps,
  FormDataConsumer,
  Labeled,
  LinearProgress,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useQueryWithStore
} from "react-admin";
import {
  ShowActionsToolbar,
  CreateActionsToolbar,
  EditActionsToolbar
} from "../common";
import { Choices, Motivation, MotivationType } from "./types";
import { FilterSidebar } from "./filters";

const variableNames = [
  "workoutName",
  "workoutDuration",
  "workoutIntensity",
  "previousMaxIntensity",
  "streakDays",
  "cumulativeSeconds"
] as const;

const messageOperators = ["==", "!=", ">", ">=", "<", "<="] as const;

type WorkoutName = typeof variableNames[number];
type Operator = typeof messageOperators[number];

const operatorOptions: { id: Operator; name: Operator }[] = [];
messageOperators.map((el) => {
  operatorOptions.push({
    id: el,
    name: el
  });
});

const variableNameOptions: { id: WorkoutName; name: WorkoutName }[] = [];
variableNames.map((el) => {
  variableNameOptions.push({
    id: el,
    name: el
  });
});

interface MotivationProps extends FieldProps {
  source?: keyof Motivation;
  record?: Motivation;
}

const MotivationTitle: React.FC<MotivationProps> = ({ record = {} }) => (
  <span>Motivation {record ? `"${record.variable_name}"` : ""}</span>
);

const TestValueElement: React.FC<FieldProps> = ({ record }) => {
  if (record && record.variable_name == "workoutName" && record.test_value) {
    return (
      <ReferenceField label="Workout" source="test_value" reference="workouts">
        <TextField source="name" />
      </ReferenceField>
    );
  }
  return <TextField source="test_value" />;
};

const MotivationList: React.FC = (props) => (
  <List
    bulkActionButtons={false}
    {...props}
    perPage={25}
    aside={<FilterSidebar />}
  >
    <Datagrid rowClick="show">
      <TextField source="variable_name" />
      <TextField source="operator" />
      <TestValueElement source="test_value" />
      <TextField source="motivation_type" />
      <DateField source="created" showTime />
      <EditButton />
    </Datagrid>
  </List>
);

const MotivationShow: React.FC = (props) => (
  <Show actions={<ShowActionsToolbar />} title={<MotivationTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="Id" source="id" />
      <TextField source="variable_name" />
      <TextField source="operator" />
      <Labeled label="Test value">
        <TestValueElement source="test_value" />
      </Labeled>
      <TextField source="motivation_type" />
      <DateField source="created" showTime />
      <DateField source="modified" showTime />
    </SimpleShowLayout>
  </Show>
);

const MotivationCreate: React.FC = (props) => {
  const [testValueChoices, setTestValueChoices] = useState<Choices[]>([]);

  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "motivation-types",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "ASC" }
    }
  });

  useEffect(() => {
    if (data) {
      const arr: Choices[] = [];
      (uniq(data.map((el: MotivationType) => el.name)) as unknown as []).map(
        (el: string) => {
          arr.push({
            id: el,
            name: el
          });
        }
      );
      setTestValueChoices(arr);
    }
  }, [data]);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Create actions={<CreateActionsToolbar />} {...props}>
      <SimpleForm redirect="list">
        <SelectInput
          source="variable_name"
          choices={variableNameOptions}
          validate={required()}
        />

        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.variable_name == "workoutName" ? (
                <TextInput
                  source="operator"
                  value="=="
                  defaultValue="=="
                  disabled
                />
              ) : (
                <SelectInput
                  source="operator"
                  choices={operatorOptions}
                  validate={required()}
                />
              )}
            </>
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) => (
            <Labeled
              label={
                formData.variable_name == "workoutName"
                  ? "Workout"
                  : "Test value"
              }
            >
              <></>
            </Labeled>
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.variable_name == "workoutName" ? (
                <ReferenceInput
                  source="test_value"
                  reference="workouts"
                  label="Workout"
                  perPage={200}
                >
                  <AutocompleteInput />
                </ReferenceInput>
              ) : (
                <NumberInput source="test_value" validate={required()} />
              )}
            </>
          )}
        </FormDataConsumer>

        <SelectInput
          source="motivation_type"
          choices={testValueChoices}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};

const MotivationEdit: React.FC = (props) => {
  const [testValueChoices, setTestValueChoices] = useState<Choices[]>([]);

  const { data, loading } = useQueryWithStore({
    type: "getList",
    resource: "motivation-types",
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "ASC" }
    }
  });

  useEffect(() => {
    if (data) {
      const arr: Choices[] = [];
      (uniq(data.map((el: MotivationType) => el.name)) as unknown as []).map(
        (el: string) => {
          arr.push({
            id: el,
            name: el
          });
        }
      );
      setTestValueChoices(arr);
    }
  }, [data]);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Edit
      actions={<EditActionsToolbar />}
      title={<MotivationTitle />}
      mutationMode="pessimistic"
      {...props}
    >
      <SimpleForm redirect="list">
        <TextField label="Id" source="id" />
        <TextField source="variable_name" />
        <TextField source="operator" />

        <FormDataConsumer>
          {({ formData }) => (
            <>
              {formData.variable_name == "workoutName" ? (
                <ReferenceInput
                  source="test_value"
                  reference="workouts"
                  label="Workout"
                  perPage={200}
                >
                  <AutocompleteInput />
                </ReferenceInput>
              ) : (
                <NumberInput source="test_value" validate={required()} />
              )}
            </>
          )}
        </FormDataConsumer>

        <SelectInput
          source="motivation_type"
          choices={testValueChoices}
          validate={required()}
        />

        <TextField source="created" />
      </SimpleForm>
    </Edit>
  );
};

export { MotivationList, MotivationCreate, MotivationEdit, MotivationShow };
