import React from "react";
import {
  BooleanInput,
  ChipField,
  CreateController,
  CreateView,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FieldProps,
  FileField,
  FileInput,
  Filter,
  FunctionField,
  Labeled,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  required,
  SearchInput,
  SelectArrayInput,
  ShowController,
  ShowView,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput
} from "react-admin";

import Link from "@material-ui/core/Link";

import {
  ShowActionsToolbar,
  CreateActionsToolbar,
  EditActionsToolbar,
  BGBooleanField
} from "../common";
import { Workout } from "./types";

const apiUploads = process.env.REACT_APP_API_HOST + "/uploads";

interface WorkoutProps extends FieldProps {
  source?: keyof Workout;
  record?: Workout;
}

const WorkoutTitle: React.FC<WorkoutProps> = ({ record }) => (
  <>Workout: {record ? `"${record.name}"` : ""}</>
);

const WorkoutFilter: React.FC = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn />
    <TextInput label="Filename" source="filename" alwaysOn />
    <BooleanInput label="Is enabled" source="enabled" defaultValue={true} />
  </Filter>
);

export const WorkoutList: React.FC = (props) => {
  return (
    <List filters={<WorkoutFilter />} bulkActionButtons={false} {...props}>
      <Datagrid rowClick="show">
        <TextField source="name" />
        {/* <TextField source="description" /> */}
        <ReferenceArrayField
          label="Categories"
          reference="workout-categories"
          source="categories"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField
          label="Filename"
          style={{ fontSize: "0.7rem" }}
          render={(record: any) =>
            `${
              record && record.filename.length > 40
                ? record.filename.slice(0, 20) +
                  " ... " +
                  record.filename.slice(-20)
                : record.filename
            }`
          }
        />
        <NumberField source="duration" />
        <BGBooleanField source="enabled" />
        {/* <DateField source="created" showTime />
        <DateField source="modified" showTime /> */}
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const WorkoutShow: React.FC = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView
        {...controllerProps}
        actions={
          <ShowActionsToolbar>
            <WorkoutTitle
              record={controllerProps.record as unknown as Workout}
            />
          </ShowActionsToolbar>
        }
        title={<WorkoutTitle />}
      >
        <SimpleShowLayout>
          <TextField label="Id" source="id" />
          <TextField source="name" />
          <TextField fullWidth source="description" />
          <ReferenceArrayField
            label="Categories"
            reference="workout-categories"
            source="categories"
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <FunctionField
            label="Filename"
            render={(record: any) => (
              <Link href={`${apiUploads}/${record.filename}`} target="_blank">
                {record.filename}
              </Link>
            )}
          />
          <Labeled label="Enabled">
            <BGBooleanField source="enabled" />
          </Labeled>
          <DateField source="created" showTime />
          <DateField source="modified" showTime />
        </SimpleShowLayout>
      </ShowView>
    )}
  </ShowController>
);

export const WorkoutCreate: React.FC = (props) => (
  <CreateController {...props}>
    {(controllerProps) => (
      <CreateView {...controllerProps} actions={<CreateActionsToolbar />}>
        <SimpleForm redirect="list">
          <TextInput fullWidth source="name" validate={required()} />
          <TextInput
            fullWidth
            multiline
            source="description"
            validate={required()}
          />
          <NumberInput
            source="duration"
            defaultValue={60}
            validate={required()}
          />
          <BooleanInput source="enabled" defaultValue={true} />
          <ReferenceArrayInput
            fullWidth
            source="categories"
            reference="workout-categories"
            filter={{ is_parent: false }}
            isRequired
            validate={[required()]}
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <FileInput
            source="csvFile"
            label="Upload workout file in CSV format (.csv)"
            isRequired
          >
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </CreateView>
    )}
  </CreateController>
);

export const WorkoutEdit: React.FC = (props) => (
  <Edit
    actions={<EditActionsToolbar />}
    title={<WorkoutTitle />}
    mutationMode="pessimistic"
    {...props}
  >
    <SimpleForm redirect="list">
      <TextInput disabled source="id" />
      <TextInput fullWidth source="name" validate={required()} />
      <TextInput
        fullWidth
        multiline
        source="description"
        validate={required()}
      />
      <ReferenceArrayInput
        fullWidth
        source="categories"
        reference="workout-categories"
        filter={{ is_parent: false }}
        isRequired
        validate={[required()]}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <NumberInput source="duration" defaultValue={60} validate={required()} />
      <BooleanInput source="enabled" />
      <FunctionField
        label="Filename"
        render={(record: any) => (
          <Link href={`${apiUploads}/${record.filename}`} target="_blank">
            {record.filename}
          </Link>
        )}
      />
      <FileInput
        source="csvFile"
        label="Upload workout file in CSV format (.csv)"
        isRequired
      >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
);
