import {
  WorkoutCategoryCreate,
  WorkoutCategoryShow
} from "./workoutCategories";
import WorkoutCategoryEdit from "./workoutCategoriesEdit";
import CategoriesListView from "./workoutCategoriesList";
import {
  WorkoutCreate,
  WorkoutEdit,
  WorkoutList,
  WorkoutShow
} from "./workouts";

const Workouts = {
  list: WorkoutList,
  create: WorkoutCreate,
  edit: WorkoutEdit,
  show: WorkoutShow
};

const WorkoutCategories = {
  list: CategoriesListView,
  create: WorkoutCategoryCreate,
  edit: WorkoutCategoryEdit,
  show: WorkoutCategoryShow
};

export { Workouts, WorkoutCategories };
