import {
  MotivationList,
  MotivationShow,
  MotivationCreate,
  MotivationEdit
} from "./motivations";
import {
  MotivationTypeCreate,
  MotivationTypeEdit,
  MotivationTypeList,
  MotivationTypeShow
} from "./motivationTypes";

const Motivations = {
  list: MotivationList,
  create: MotivationCreate,
  edit: MotivationEdit,
  show: MotivationShow
};

const MotivationTypes = {
  list: MotivationTypeList,
  create: MotivationTypeCreate,
  edit: MotivationTypeEdit,
  show: MotivationTypeShow
};

export { Motivations, MotivationTypes };
