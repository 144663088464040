import { FC } from "react";
import { Login, LoginForm } from "react-admin";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";

import backgroundImg from "../assets/img/background.svg";

const useStyles = makeStyles(() =>
  createStyles({
    main: {
      backgroundColor: "#e87f27",
      backgroundPositionY: "-100px"
    },
    card: {
      padding: "24px"
    }
  })
);

const CustomLoginForm = withStyles({
  button: { background: "#F15922" }
})(LoginForm);

const BGLoginPage: FC = () => {
  return (
    <Login classes={useStyles()} backgroundImage={backgroundImg}>
      <CustomLoginForm />
    </Login>
  );
};

export default BGLoginPage;
