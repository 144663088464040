import React, { cloneElement, FC } from "react";
import {
  Create,
  CreateButton,
  Datagrid,
  DateField,
  downloadCSV,
  Edit,
  EditButton,
  ExportButton,
  FieldProps,
  FunctionField,
  List,
  ListActionsProps,
  required,
  sanitizeListRestProps,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useNotify
} from "react-admin";
import { ImportButton } from "react-admin-import-csv";
import jsonExport from "jsonexport/dist";
import {
  ShowActionsToolbar,
  CreateActionsToolbar,
  EditActionsToolbar
} from "../common";

import pick from "lodash/pick";
import remove from "lodash/remove";

export interface Faq {
  id: string;
  question: string;
  answer: string;
  created: Date;
  modified: Date;
}

interface FaqProps extends FieldProps {
  source?: keyof Faq;
  record?: Faq;
}

const FaqTitle: FC<FaqProps> = ({ record }) => (
  <span>Faq {record ? `"${record.question}"` : ""}</span>
);

const faqsExporter = (posts: Faq[]) => {
  const postsForExport = posts.map((faq: Faq) =>
    pick(faq, ["id", "question", "answer", "created"])
  );
  jsonExport(
    postsForExport,
    {
      headers: ["id", "question", "answer", "created"] // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "faqs"); // download as 'faqs.csv` file
    }
  );
};

const ListActions: FC<ListActionsProps> = (props) => {
  const { className, filters, exporter, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total
  } = useListContext();

  const notify = useNotify();

  const config = {
    logging: false,
    parseConfig: {
      dynamicTyping: true,
      skipEmptyLines: true
    },
    preCommitCallback: (action: "create" | "overwrite", values: any[]) => {
      return new Promise((resolve) => {
        /**
         * Remove all the rows that don't have proper ids as well as
         * question & answer minimum lengths
         */
        resolve(
          remove(values, (el: Faq) => {
            return (
              el.id?.length == 24 &&
              el.question?.length > 10 &&
              el.answer?.length > 10
            );
          })
        );
      });
    },
    postCommitCallback: (resp: any[]) => {
      if (resp.length) {
        let msg = "";
        try {
          msg = resp[0].err?.message;
        } catch (err) {
          console.log("postCommitCallback error", err, resp);
        }

        if (msg) {
          notify(msg, "warning");
        }
      }
    }
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button"
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={1000}
        exporter={faqsExporter}
      />
      <ImportButton {...props} {...config} />
    </TopToolbar>
  );
};

const FaqList: FC = (props) => (
  <List
    {...props}
    sort={{ field: "question", order: "asc" }}
    actions={<ListActions />}
  >
    <Datagrid rowClick="show">
      <TextField source="question" />
      <FunctionField
        label="Answer"
        render={(record: any) =>
          `${
            record && record.answer.length > 60
              ? record.answer.slice(0, 60) + "..."
              : record.answer
          }`
        }
      />
      {/* <DateField source="created" showTime /> */}
      <DateField source="modified" showTime />
      <EditButton />
    </Datagrid>
  </List>
);

const FaqShow: FC = (props) => (
  <Show actions={<ShowActionsToolbar />} title={<FaqTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="Id" source="id" />
      <TextField source="question" />
      <TextField fullWidth source="answer" />
      <DateField source="created" showTime />
      <DateField source="modified" showTime />
    </SimpleShowLayout>
  </Show>
);

const FaqCreate: FC = (props) => (
  <Create actions={<CreateActionsToolbar />} {...props}>
    <SimpleForm redirect="list">
      <TextInput source="question" validate={required()} />
      <TextInput fullWidth source="answer" validate={required()} />
    </SimpleForm>
  </Create>
);

const FaqEdit: FC = (props) => {
  return (
    <Edit
      actions={<EditActionsToolbar />}
      title={<FaqTitle />}
      mutationMode="pessimistic"
      {...props}
    >
      <SimpleForm redirect="list">
        <TextInput disabled source="id" />
        <TextInput source="question" validate={required()} />
        <TextInput fullWidth source="answer" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

export default {
  list: FaqList,
  create: FaqCreate,
  edit: FaqEdit,
  show: FaqShow
};
