import { FC } from "react";
import {
  Datagrid,
  DateField,
  FieldProps,
  FunctionField,
  List,
  Show,
  SimpleShowLayout,
  TextField
} from "react-admin";

export interface Feedback {
  id: string;
  email: string;
  description: string;
  date: Date;
}

interface FeedbackProps extends FieldProps {
  source?: keyof Feedback;
  record?: Feedback;
}

const FeedbackTitle: FC<FeedbackProps> = ({ record }) => (
  <span>Feedback {record ? `"${record.email}"` : ""}</span>
);

const FeedbackList: FC = (props) => (
  <List
    bulkActionButtons={false}
    sort={{ field: "date", order: "DESC" }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="email" />
      <FunctionField
        label="Description"
        render={(record: any) =>
          `${
            record && record.description.length > 90
              ? record.description.slice(0, 90) + "..."
              : record.description
          }`
        }
      />
      <DateField source="date" showTime />
    </Datagrid>
  </List>
);

const FeedbackShow: FC = (props) => (
  <Show title={<FeedbackTitle />} {...props}>
    <SimpleShowLayout>
      <TextField label="Id" source="id" />
      <TextField source="email" />
      <TextField fullWidth source="description" />
      <DateField source="date" showTime />
    </SimpleShowLayout>
  </Show>
);

export default {
  list: FeedbackList,
  show: FeedbackShow
};
